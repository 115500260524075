import React from "react";
import './modal.scss';
import {RiCloseCircleFill} from "react-icons/ri";
import 'react-tooltip/dist/react-tooltip.css';
import {Tooltip} from 'react-tooltip';

const Modal = ({ isModalOpen, handleURLCopied, handleAddNetwork, modalRef, rpcURL, handleCloseModal }) => {

  return (
    <>
      <div ref={modalRef} className={`popup_main_div ${isModalOpen && 'display'}`}>
        <div className='popup_div'>
          <span className='close_icon'><RiCloseCircleFill onClick={handleCloseModal}/></span>
          <p className='popup_heading'> Guzzler #{rpcURL?.tokenId}</p>
          <p className='popup_network'>{rpcURL?.network}</p>
          <div className='middle_link_div'>
            {rpcURL?.URL === undefined ?
              <p className='url_text'>Generating RPC... Please check back soon.</p> :
              <>
                <p className='url_text'>{rpcURL?.URL}/{rpcURL?.key}</p>
                <Tooltip id="my-tooltip" openOnClick={true} className='' />
                <button className='url_copy_btn' onClick={handleURLCopied} id='copy_btn' data-tooltip-id="my-tooltip"
                        data-tooltip-content="copied!">copy
                </button>
              </>
            }
          </div>
          <div className='popup_btn_div'>
            <button className='add_btn' onClick={handleAddNetwork} disabled={rpcURL?.URL === undefined}>Add to
              metamask
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
export default Modal;
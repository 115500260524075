import React, {useEffect, useState} from 'react';
import './privateAccess.scss'
import {RiWallet3Fill} from "react-icons/ri";
import Modal from "../../components/modal/Modal";
import {generateRPC, getAllTokens, getApiKeys, getRPC, setAuthToken} from "../../utils/backendApi";
import configs from '../../utils/config.json';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {IoMdArrowDropdown, IoMdArrowDropup} from "react-icons/io";

const PrivateAccess = ({ account, loading, setLoading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allTokens, setAllTokens] = useState([]);
  const [isInWhitelist, setIsInWhitelist] = useState(true);
  const [rpcURL, setRpcURL] = useState();
  const modalRef = React.useRef(null);

  const toastStyles = {
    textAlign: 'center',
    borderRadius: '4px',
    color: "rgba(255,255,255,0.8)",
    border: "1px solid rgb(132,136,132,0.5)",
    fontSize: "13px",
    fontFamily: "'Menlo', sans-serif",
    backgroundColor: "rgba(0,0,0,1)",
    backdropFilter: "blur(18px)",
    boxShadow: '0 0 18px rgb(0 0 0 / 80%)',
  }

  const toastOptions = {
    type: "success",
    autoClose: 3000,
    position: "top-center",
    closeButton: false,
    hideProgressBar: true,
    closeOnClick: false,
    theme: "dark",
    icon: false,
    style: toastStyles
  }

  useEffect(() => {
    getData()
  }, [account]);

  const getData = async () => {
    setLoading(true)
    setIsInWhitelist(true)
    const token = await JSON.parse(localStorage.getItem('auth token'));
    if (!token) {
      const isTokenAvailable = await setAuthToken();
      getData()
    } else {
      const res = await getApiKeys()
      if (res.status === 401) {
        await setAuthToken();
        getData()
      } else if (res.status === 400) {
        setIsInWhitelist(false)
        setLoading(false)
      } else {
        const response = await getAllTokens(account)
        const tokens = response.tokens?.sort((a, b) => {
          return a - b
        })
        setAllTokens(tokens?.map((item) => {
          if (res.length > 0) {
            const apikey = res?.find(elem => elem.tokenId === item)
            if (!apikey) {
              item = {
                id: item,
                isEthViewable: false,
                isPolygonViewable: false,
                selectedEthRegion: 'us-east-1',
                selectedPolygonRegion: 'us-east-1',
                is1DropDownOpen: false,
                is2DropDownOpen: false
              }
            } else {
              item = {
                id: item,
                isEthViewable: false,
                isPolygonViewable: false,
                selectedEthRegion: 'us-east-1',
                selectedPolygonRegion: 'us-east-1',
                is1DropDownOpen: false,
                is2DropDownOpen: false,
              }
              if (apikey.data?.polygonNodeId) {
                item = {
                  ...item,
                  isPolygonViewable: true,
                  polygonNodeId: apikey.data.polygonNodeId.id,
                  selectedPolygonRegion: apikey.data.polygonNodeId.region,
                }
              }
              if (apikey.data?.ethNodeId) {
                item = {
                  ...item,
                  isEthViewable: true,
                  ethNodeId: apikey.data.ethNodeId.id,
                  selectedEthRegion: apikey.data.ethNodeId.region,
                }
              }
            }
          } else {
            item = {
              id: item,
              isEthViewable: false,
              isPolygonViewable: false,
              selectedEthRegion: 'us-east-1',
              selectedPolygonRegion: 'us-east-1',
              is1DropDownOpen: false,
              is2DropDownOpen: false
            }
          }
          return item
        }))
        setLoading(false)
      }
    }
  }

  const handleCloseModal = () => {
    if (modalRef.current !== null) {
      modalRef.current.classList.add("out");
    }
    setTimeout(() => {
      setIsModalOpen(false)
      setRpcURL()
    }, 400)
  }

  window.ethereum.on('accountsChanged', async () => {
    localStorage.removeItem('auth token')
  });

  const handleViewRpc = async (nodeId, network, id) => {
    setLoading(true)
    const res = await getRPC(nodeId)
    if (res) {
      setRpcURL({
        URL: res.details.https_endpoint,
        key: res.details.auth_key,
        tokenId: id,
        network
      })
      setLoading(false)
      setTimeout(() => {
        setIsModalOpen(true)
      }, [300])
    }
  }

  const handleAddNetwork = () => {
    if (modalRef.current !== null) {
      modalRef.current.classList.add("out");
    }
    setTimeout(() => {
      setIsModalOpen(false)
    }, 400)
    if (rpcURL?.network === 'Polygon') {
      window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [{
          chainId: "0x89",
          rpcUrls: [`${rpcURL?.URL}/${rpcURL?.key}`],
          chainName: `GG Poly #${rpcURL?.tokenId}`,
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18
          },
          blockExplorerUrls: ["https://polygonscan.com/"]
        }]
      });
    } else {
      window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [{
          chainId: "0x1",
          rpcUrls: [`${rpcURL?.URL}/${rpcURL?.key}`],
          chainName: `GG ETH #${rpcURL?.tokenId}`,
          nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
          },
          blockExplorerUrls: ["https://etherscan.io/"]
        }]
      });
    }
  }

  const handleGenerate = async (item, network) => {
    setLoading(true)
    const dataParams = {
      tokenId: [item.id],
      region: network === 'Ethereum' ? item.selectedEthRegion : item.selectedPolygonRegion,
      chain: network === 'Ethereum' ? 'eth' : 'polygon'
    }
    const response = await generateRPC(dataParams)
    if (response?.status == 200) {
      toast('The request has been accepted. RPC will be generated in few minutes.', toastOptions)
      getData()
    } else {
      toast('Something went wrong. Please try again.', toastOptions)
    }
  }

  const handleURLCopied = () => {
    document.getElementById('my-tooltip').classList.remove('display_tooltip')
    navigator.clipboard.writeText(`${rpcURL?.URL}/${rpcURL?.key}`)
    setTimeout(() => {
      document.getElementById('my-tooltip').classList.add('display_tooltip')
    }, 3000)
  }

  const handleClick = (id, network) => {
    setAllTokens(allTokens.map((item) => {
      if (item.id === id) {
        if (network === 'ethereum') {
          item.is1DropDownOpen = item.is1DropDownOpen !== true;
          item.is2DropDownOpen = false;
        }
        if (network === 'polygon') {
          item.is2DropDownOpen = item.is2DropDownOpen !== true;
        }
      }
      return item
    }))
  }

  const handleSelect = (elem, id, network) => {
    setAllTokens(allTokens.map((item) => {
      if (item.id === id) {
        if (network === 'ethereum') {
          item.selectedEthRegion = elem
          item.is1DropDownOpen = false
        }
        if (network === 'polygon') {
          item.selectedPolygonRegion = elem
          item.is2DropDownOpen = false
        }

      }
      return item
    }))


  }

  return (
    <>
      <ToastContainer/>
      <div className='main_div'>
        <div className='middle_glass_box'>
          <div className='heading_div'>
            <p className='heading'>Private blockchain access</p>
            <div className='wallet_div'>
              <div className='wallet_logo'><RiWallet3Fill/></div>
              <p className='wallet_address'>{`${account.slice(0, 9)}...`}</p>
            </div>
          </div>
          <div className='table_box_div'>
            {isInWhitelist ?
              allTokens.length > 0 ?
                <>
                  <div className='data_heading_row'>
                    <p className='header_text'>Guzzler</p>
                    <p className='header_text'>Network</p>
                    <p className='header_text'>Location</p>
                    <p className='header_text'>RPC</p>
                  </div>
                  <div className='scroll_div'>
                    {
                      allTokens.map((item, i) => (
                        <div className='data_row_div' key={i}>
                          <p className='id_text'>#{item?.id}</p>
                          <div className='network_div'>
                            <p className='network_text'>Ethereum</p>
                            <p className='network_text'>Polygon</p>
                          </div>
                          <div className='network_div'>
                            {item?.isEthViewable ?
                              <p
                                className='network_text'>{item.selectedEthRegion === 'uksouth' ? 'uk-south' : item.selectedEthRegion === 'asia-southeast1' ? 'asia-southeast-1' : item.selectedEthRegion}</p> :
                              <div className="dropdown">
                                <p onClick={() => handleClick(item.id, 'ethereum')}
                                   className='network_text'>{item.selectedEthRegion === 'uksouth' ? 'uk-south' : item.selectedEthRegion === 'asia-southeast1' ? 'asia-southeast-1' : item.selectedEthRegion}{item.is1DropDownOpen ?
                                  <IoMdArrowDropup className='drop_btn'/> :
                                  <IoMdArrowDropdown className='drop_btn'/>}</p>
                                <div className={`dropdown-content ${item.is1DropDownOpen && 'trueDropDown'}`}>
                                  <ul>
                                    {configs.ethRegion?.map((elem, i) => elem !== item.selectedEthRegion && (
                                      <li key={i}
                                          onClick={() => handleSelect(elem, item.id, 'ethereum')}>{elem === 'uksouth' ? 'uk-south' : elem === 'asia-southeast1' ? 'asia-southeast-1' : elem}</li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            }
                            {
                              item?.isPolygonViewable ?
                                <p
                                  className='network_text'>{item.selectedPolygonRegion === 'uksouth' ? 'uk-south' : item.selectedPolygonRegion}</p> :
                                <div className="dropdown">
                                  <p onClick={() => handleClick(item.id, 'polygon')}
                                     className='network_text'>{item.selectedPolygonRegion === 'uksouth' ? 'uk-south' : item.selectedPolygonRegion}{item.is2DropDownOpen ?
                                    <IoMdArrowDropup className='drop_btn'/> :
                                    <IoMdArrowDropdown className='drop_btn'/>}</p>
                                  <div className={`dropdown-content ${item.is2DropDownOpen && 'trueDropDown'}`}>
                                    <ul>
                                      {configs.polygonRegion?.map((elem, i) => elem !== item.selectedPolygonRegion && (
                                        <li key={i}
                                            onClick={() => handleSelect(elem, item.id, 'polygon')}>{elem === 'uksouth' ? 'uk-south' : elem}</li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                            }
                          </div>
                          <div className='button_div'>
                            {
                              item?.isEthViewable ?
                                <button onClick={() => handleViewRpc(item?.ethNodeId, 'Ethereum', item.id)}
                                        className='view_generate_btn'>view
                                </button> :
                                <button className='view_generate_btn'
                                        onClick={() => handleGenerate(item, 'Ethereum')}>generate
                                </button>
                            }
                            {
                              item?.isPolygonViewable ?
                                <button onClick={() => handleViewRpc(item?.polygonNodeId, 'Polygon', item.id)}
                                        className='view_generate_btn'>view
                                </button> :
                                <button className='view_generate_btn'
                                        onClick={() => handleGenerate(item, 'Polygon')}>generate
                                </button>
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </> :
                <div className='empty_div'>{!loading && 'You don’t have any Guzzlers.'}</div> :
              <div className='empty_div'>{!loading && 'Sorry, you do not have access.'}</div>
            }
          </div>
        </div>
      </div>
      <Modal modalRef={modalRef}
             rpcURL={rpcURL}
             handleAddNetwork={handleAddNetwork}
             handleCloseModal={handleCloseModal}
             handleURLCopied={handleURLCopied}
             isModalOpen={isModalOpen}/>
    </>
  )
}

export default PrivateAccess;
import React, {useEffect, useState} from 'react';
import './App.scss';
import Header from "./components/header/Header";
import PrivateAccess from "./pages/private_access/PrivateAccess";
import {useMetaMask} from "metamask-react";
import Loader from "./components/loader/Loader";
import Connect from "./components/connect/Connect";

const App = () => {
  const [loading, setLoading] = useState(false);
  const { status, connect, account } = useMetaMask();

  useEffect(() => {
    if (status === 'notConnected') {
      localStorage.removeItem('auth token')
    }
  }, [status]);

  return (
    <div className="App">
      <Header/>
      <Loader loading={loading}/>

      {
        status === 'connected' ?
          <PrivateAccess setLoading={setLoading} loading={loading} account={account}/>
          :
          <Connect status={status} connect={connect}/>
      }
    </div>
  );
}

export default App;

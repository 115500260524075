import axios from "axios";
import {ethers} from "ethers";
import Web3Token from "web3-token";

const BASE_URL = "https://ggnodeapi.0xytocin.online/rest/"

export const getAllTokens = async (userAddress) => {
  try {
    const token = JSON.parse(localStorage.getItem('auth token'));
    const { data } = await axios.get(BASE_URL + `user/getTokens?ownerAddress=${userAddress}`, { headers: { 'authorization': token } });
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const getApiKeys = async () => {
  try {
    const token = JSON.parse(localStorage.getItem('auth token'));
    const { data } = await axios.get(BASE_URL + 'user/getAPIkeyByAddress', { headers: { 'authorization': token } });
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return error.response;
  }
};

export const getRPC = async (nodeId) => {
  try {
    const token = JSON.parse(localStorage.getItem('auth token'));
    const { data } = await axios.get(BASE_URL + `user/getRPCURL/${nodeId}`, { headers: { 'authorization': token } });
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const generateRPC = async (dataParams) => {
  try {
    const token = JSON.parse(localStorage.getItem('auth token'));
    const data = await axios.post(BASE_URL + 'user/generateAPIkey', dataParams, { headers: { 'authorization': token } });
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const setAuthToken = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const token = await Web3Token.sign(async msg => await signer.signMessage(msg), '1d');
    localStorage.setItem('auth token', JSON.stringify(token));
    return true;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
}
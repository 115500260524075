import React from "react";
import './header.scss';

const Header = () => {

  return (
    <div className='head_div'>
      <div className='logo_div'>
        <h1 className='logo'>Gas guzzlers</h1>
      </div>
    </div>
  )
}

export default Header;